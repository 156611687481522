<form role="form" [ngStyle]="{'background-image':'url(../assets/img/bgs.jpg)','background-size': 'cover','height':'100%'}" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" #passform="ngForm">
	<div class="container container-sm animated fadeInDown">
		<div style="background-color: #e6eff5;margin-top: 100px;" class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-xs-12">
			<div *ngIf="!linkExpired">
				<div class="text-center" style="margin-top: 30px;">
					<h3>Reset Password</h3>
				</div>
				<fieldset class="mt20">
					<div class="col-sm-6">
						<label class="control-label">
							<span style="color: #b40a09;">*</span> New Password :
						</label>
						<input placeholder="Password" name="password" type="password" formControlName="password"
						class="input-filter form-control"/>
					</div>
					<div class="col-sm-6">
						<label class="control-label">
							<span style="color: #b40a09;">*</span> Confirm Password :
						</label>
						<input placeholder="Confirm Password" name="confirmPassword" type="password"
						formControlName="confirmPassword" class="input-filter form-control"/>
					</div>
					<div class="col-sm-12 mt-lg text-center">
						<div type="danger">
							<div *ngIf="f.password.value" [ngClass]="resetPasswordForm.errors?.passwordNotMatch?'ErrorColor':'Suucesscolor'">
								Password and Confirm Password should match.
							</div>
							<div [ngClass]="f.password.errors?.minlengtherror?'ErrorColor':'Suucesscolor'">
								Password Must have atleast 8 characters.
							</div>
							<div [ngClass]="f.password.errors?.capitalerror?'ErrorColor':'Suucesscolor'">
								Password Must have atleast 1 capital letter.
							</div>
							<div [ngClass]="f.password.errors?.numericerror?'ErrorColor':'Suucesscolor'">
								Password must have atleast 1 numeric value.
							</div>
						</div>
					</div>
					<div class="col-sm-12 form-group mt-lg text-center">
						<button id="btnSubmit" type="submit" class="btn btn-info"><strong><i *ngIf="loadershow" class="fa fa-spinner fa-spin mr-sm" aria-hidden="true"></i>Submit</strong></button>
					</div>
					<div class="col-sm-12 text-center text-black mt-lg" style="font-size: 11px;">&nbsp;&copy; {{year}}&nbsp;-&nbsp;<a href="http://3cubeservices.com/" target="_blank" class="text-black">3 Cube Services</a></div>
					<div class="col-sm-12 form-group text-center">
						<a href="mailto:info@3cubeservices.com" style="color:#000; font-weight:inherit; ">info@3cubeservices.com </a>
					</div>
				</fieldset>
			</div>
			<div *ngIf="linkExpired">
				<div class="text-center" style="margin-top: 30px;">
					<h3>This link is expired</h3>
				</div>
			</div>
		</div>
	</div>
</form>