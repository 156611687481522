import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject, concat } from 'rxjs';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { timer, of } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter, take, takeUntil, switchMap, catchError, retry } from "rxjs/operators";
import { FormControl } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	baseURL = environment.API_GENERAL_URL;
	private mailOnlyCountChange = new Subject<any>();

	dataByEvent: EventEmitter<string> = new EventEmitter<string>();

	constructor(private httpClient: HttpClient) { }

	sendOnlyCountChangeEvent() {
		this.mailOnlyCountChange.next();
	}
	getOnlyCountChangeEvent(): Observable<any> {
		return this.mailOnlyCountChange.asObservable();
	}

	public loginAPI(user: any) {
		var data = { username: user.email, password: user.password }
		return this.httpClient.post(this.baseURL + "api/signin/", data);
	}

	public generate_qr(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"api/generate_qr",data,{responseType:"blob"});
	}

	public verifyGoogleAuthOTP(params:any){
		let headers = new HttpHeaders();
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"api/verify_otp",options);
	}

	public logout() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/signOut/", { headers: headers });
	}

	public sendResetPasswordLink(data: any) {
		return this.httpClient.post(this.baseURL + "api/sendResetPasswordLink/", data);
	}

	public setNewPassowrd(data: any) {
		return this.httpClient.post(this.baseURL + "api/setPassowrd/", data);
	}

	public checkLinkExpiry(data: any) {
		return this.httpClient.post(this.baseURL + "api/checkLinkExpiry/", data);
	}

	public resetPassword(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/changePassword/", data, { headers: headers });
	}

	public deleteMedicine(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/MedicineMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getMedicineDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateMedicine(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/MedicineMasterApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public salesOrderApi(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getMedicineSuggetions", data, { headers: headers });
	}

	public detectExcelColumns(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/detectExcelColumns", data, { headers: headers });
	}

	public saveMedicineQuantity(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/addSalesOrder";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public getExcelTemplateFlagState(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/FlagStateExcelTemplateApi", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public flagStateExcelImportApi(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/FlagStateExcelImportApi", data, { headers: headers });
	}

	public getFlagDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/FlagStateApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateFlagState(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/FlagStateApi";
		if(data.flagState.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.flagState.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public deleteFlagState(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/FlagStateApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getPODetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getSalesOrderItems", data, { headers: headers });
	}

	public deleteVessel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/VesselMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateVessel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/VesselMasterApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getVesselDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/VesselMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public checkDuplicateImo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/checkDuplicateImo", data, { headers: headers });
	}

	public checkEmailOfVessel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/checkEmailOfVessel", data, { headers: headers });
	}

	public deletePO(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/deleteSalesOrder";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public removeOrderItems(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/removeSalesOrderItems";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public changeOrderItems(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/changeSalesOrderItems";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public deleteRawData(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/RawDataApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateRawData(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/RawDataApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public removeMappingItem(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/deleteFlagStateMappingItem", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public removeBrandMappingItem(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/deleteBrandNameMappingItem", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getAllForms(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineFormApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getSOItemsExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/excelSalesOrderItems", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getProcurementSheet(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/ProcurementSheetDownload", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public deleteVendor(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/VendorApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getVendorDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/VendorApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateVendor(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/VendorApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getAllVendors(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/VendorApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public deletePurchaseOrder(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/PurchaseOrderMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getPurchaseOrderDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/PurchaseOrderMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdatePurchaseOrder(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/PurchaseOrderMasterApi";
		if(data.PurchaseOrder.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.PurchaseOrder.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public removePurchaseOrderLineItem(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/PurchaseOrderLineItemDelete", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getAllCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/CompanyApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/CompanyApi ";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getExcelTemplatePurchaseOrder() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.get(this.baseURL + "api/PurchaseOrderTemplateApi", { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public createPurchaseOrderFromExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/PurchaseOrderImportApi", data, { headers: headers });
	}

	public PurchaseOrderBatchList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/PurchaseOrderBatchList", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public PurchaseOrderBatchListByMedId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/PurchaseOrderBatchList", data, { headers: headers });
	}

	public PurchaseOrderBatchListAll(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/MultiPurchaseOrderBatchList", data, { headers: headers });
	}

	public getMedicineStockExcel(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineStockMasterExcelApi", { params: new HttpParams(httpParams),headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getMedicineStockCompletedOrdersExcel(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineStockMasterExcelApiCompletedOrders", { params: new HttpParams(httpParams),headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getAllBrands(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/BrandNameApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createBrand(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/BrandNameApi ";
		if(data.BrandName.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.BrandName.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	/* public getQuotationDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getSalesOrderQuotation", data, { headers: headers });
	} */

	public generateSalesOrderQuotation(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/generateSalesOrderQuotation";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public getQuotationExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/excelSalesOrderQuotation", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public deleteQuotation(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/deleteQuotation";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public addNewQuotation(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/addNewQuotation";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public getQuotationDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/getLineItemQuotationList ";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public removeQuotationItems(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/removeQuotationLineItem ";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public editQuotation (data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/editQuotation  ";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public excelDownloadForQuotation(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/excelDownloadForQuotation", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public approveQuotation (data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/approveQuotation";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public createSalesOrder (id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/createSalesOrder";
		return this.httpClient.post(url, {quot_Id:id}, { headers: headers });
	}

	public approveSalesOrder (id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/approveSalesOrder";
		return this.httpClient.post(url, {so_id:id}, { headers: headers });
	}
	
	public getAllStorages(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineStorageApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public LastFivePurchaseAmountOfItem(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/LastFivePurchaseAmountOfItemApi", data, { headers: headers });
	}

	public addNewStorage(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/MedicineStorageApi";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public addNewForm(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/MedicineFormApi";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public deleteBrand(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/BrandNameApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getBrandDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/BrandNameApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public deleteMfg(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/CompanyApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getMfgdetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/CompanyApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public addVesselInventry(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/addVesselInventry";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public getviewVesselInventry(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/viewVesselInventry", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public addPOPDF(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadPOPDF";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public UploadMedicineImage(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadMedicineImage";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public addFlagPDF(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadFlagFile";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public addVesselPDF(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadMedCertificate";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public excelDownloadMedicineMaster(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/excelDownloadMedicineMaster", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public LastFivePurchaseAmountOfBrand(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/LastFivePurchaseAmountOfBrandApi", data, { headers: headers });
	}

	public excelDownloadBrandMaster(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/excelDownloadBrandMaster", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public deleteUser(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/PharmaUsersApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateUser(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/PharmaUsersApi";
		if(data.Id){
			return this.httpClient.post(url, data, { headers: headers });
		}else{
			delete data.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getUserDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/PharmaUsersApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getAllModules() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/PharmaModulesApi",{}, { headers: headers });
	}

	public getMaxPrizeOfMedicine(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/MaxPrizeOfMedicineItemApi";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public restoreFlagState(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_flagState/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreVendor(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_vendor/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreBrand(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_brand/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreVessel(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_VesselMaster/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreCompany(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_Company/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreMedicine(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_MedicineMaster/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public getUserAccess() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {"Id":localStorage.getItem("phuser")} } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/PharmaUsersApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public syncFlagStateMedicines(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/syncFlagStateMedicines", data, { headers: headers });
	}

	public getAllFinalMedicineList(so_id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/GetAllFinalMedicineList";
		return this.httpClient.post(url, {so_id:so_id}, { headers: headers });
	}

	public getAnnexure(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/AnnexureInvoiceDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getShipmentBoxes(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ShipmentBoxesExcelDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getPOBillsExcel(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/POBillsExcelDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getPOBillsPdf(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/POBillsPdfDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getPackaging(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/PackagingListDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getLabels(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/CreateLabels",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public POBillsPdfDownload(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/POBillsPdfDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public createSelectedLabelsOnly(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/CreateSelectedLabelsOnly",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public salesBoxDetailsAPI(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/SalesBoxDetailsAPI", data, { headers: headers });
	}

	public salesBoxDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/SalesBoxDetailsAPI", { params: new HttpParams(httpParams), headers: headers });
	}

	public salesBoxDetailsAPIUpdate(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.put(this.baseURL + "api/SalesBoxDetailsAPI", data, { headers: headers });
	}

	public getExporters(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/ExporterMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateExporter(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/ExporterMasterApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public deleteExporter(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/ExporterMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getConsignee(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/ConsigneeMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateConsignee(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/ConsigneeMasterApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public deleteConsignee(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/ConsigneeMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateLogistics(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/LogisticsDetailsMasterApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getLogistics(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/LogisticsDetailsMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public getLogisticsFormDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/LogisticsFormsApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getAllLogisticsForms(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/LogisticsFormsApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public deleteLogisticsForm(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/LogisticsFormsApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getAllFormTypes(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/FormTypeMasterApi ", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateLogiscticsForm(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/LogisticsFormsApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}
	
	public addLogisticsFormPDF(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadFormPdf";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public restoreUser(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_user/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreExporter(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_Exporter/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreForm(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_form/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreConsignee(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_Consignee/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public printLogisticsForms(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/PrintLogisticsForms", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public validateContactNumber(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/phonenumber_validation", data, { headers: headers });
	}

	contactNumberValidator =  (time: number = 500) => {
		return (input: FormControl) => {
			return timer(time).pipe(switchMap(() => {
				let data:any = {phonenumber:input.value};
				return this.validateContactNumber(data)
			}),map((res:any) => {
				if(!input.value){
					return null;
				}
				if(res){
					return null;
				}else{
					return { pattern: true };
				}
			}),catchError((e) => {
				if(!input.value){
					return of(null);
				}
				return of({ pattern: true });
			}));
		};
	};

	public getLast3CId(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/GetLast3cidOfMedicineMaster ",data, { headers: headers });
	}

	public LastFivePurchaseAmountBrand(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/LastFivePurchaseAmountOfMappingApi  ",data, { headers: headers });
	}

	public unapproveQuotationAndDeleteSalesOrder(quot_id:any,so_id:any): Observable<any[]>{
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let response1 =  this.httpClient.post(this.baseURL+"api/UnapprovedQuotation",{quot_id:quot_id},{headers:headers});
		let response2 =  this.httpClient.post(this.baseURL+"api/deleteSalesOrder",{order_id:so_id},{headers:headers});
		return forkJoin([response1, response2]);
	}

	public unapproveQuotation(quot_id:any){
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return  this.httpClient.post(this.baseURL+"api/UnapprovedQuotation",{quot_id:quot_id},{headers:headers});
	}

	public unapproveSalesOrder(so_id:any){
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL+"api/UnapprovedSalesOrder",{so_id:so_id},{headers:headers});
	}

	public deleteBox(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/BoxMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getBoxDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/BoxMasterApi", { params: new HttpParams(httpParams), headers: headers});
	}

	public AvailablePurchaseMedicines(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/AvailablePurchaseMedicines", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateBox(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/BoxMasterApi";
		if(data.BoxMaster.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.BoxMaster.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public removeBoxMappingItem(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/DeleteSingleBoxItem", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public moveMedicineFromBox(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/MoveMedicineToAnotherBox";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public boxQRCode(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/generateQrcode/", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public restoreBox(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_BoxMaster/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public read_purchase_old(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/read_purchase";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public read_purchase(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/ReadPoTextract";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public medicineWithBoxNoList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineWithBoxNoList", { params: new HttpParams(httpParams), headers: headers });
	}

	public purchaseOrderDownload(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/PurchaseOrderDownload", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public purchaseOrderDownloadVendor(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/PurchaseOrderForVendorDownload", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getMedicineBrands(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineWithBrandNameList", { params: new HttpParams(httpParams), headers: headers });
	}

	public getWebhooks(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/WebhookAPI", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateWebhooks(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/WebhookAPI";
		if(data.id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public deleteWebhooks(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/WebhookAPI", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public restoreWebhooks(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_Webhooks/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public readuploadexcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/readuploadexcel", data, { headers: headers });
	}

	public MedicineBrandQtyExpList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/MedicineBrandQtyExpList";
		return this.httpClient.post(url, data, {headers: headers});
	}

	public getVesselMedicineSuggetions(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/getVesselMedicineSuggetions";
		return this.httpClient.post(url, data, {headers: headers});
	}

	public quotationProcurementSheet(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/QuotationProcurementSheet", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public POBillsTableFormatPdf(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/POBillsTableFormatPdf",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public printBilltoCompanyInvoice(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/PrintBilltoCompanyInvoice",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public createUpdateMCC(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/VesselMedicalChestCertificateApi";
		if(data.id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getMCCDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/GetVesselMCCDetails", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}
	public MedicalChestCertificateDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicalChestCertificateDetails", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public MedicalChestCertificatePdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/MedicalChestCertificatePdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getAllCountry(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/CountryMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public getMedicineList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineList", { params: new HttpParams(httpParams), headers: headers });
	}

	public updateVesselInventryMapping(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/UpdateVesselInventryMapping";
		return this.httpClient.put(url, data, { headers: headers });
	}

	public activityMatrix(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/activityMatrix", { params: new HttpParams(httpParams), headers: headers });
	}

	public alerts(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/alerts", { params: new HttpParams(httpParams), headers: headers });
	}

	public invoiceModuleAlerts(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/InvoiceModuleAlerts", { params: new HttpParams(httpParams), headers: headers });
	}

	public companyMonthWiseMCCDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/CompanyMonthWiseMCCDetails", { params: new HttpParams(httpParams), headers: headers });
	}

	public confirmAgent(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/ConfirmAgent";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public shipmentDeliver(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/ShipmentDeliver";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public vesselInventryExcelDownload(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/VesselInventryExcelDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public vesselInventryBatchWiseExcelDownload(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/VesselInventryBatchWiseExcelDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public QuotationActiveStatusUpdate(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/QuotationActiveStatusUpdate";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public track_shipments(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/dhl/track_shipments", { params: new HttpParams(httpParams), headers: headers });
	}

	public getTask(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/TaskManagementApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateTask(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/TaskManagementApi";
		if(data.id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getTaskCategories(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/CheckListMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public doneTask(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/TaskDoneApi";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public closeTask(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/TaskCloseApi";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public excelDownloadFlagStateMaster(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/excelDownloadFlagStateMaster",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public reset2fa(data:any){
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"api/reset2fa",data,{headers:headers});
	}

	public UploadProofOfDelivery(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadProofOfDelivery";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public UploadInvoiceBill(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadInvoiceBill";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public orderBilled(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/OrderBilled";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public paymentDone(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/PaymentDone";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public BillIsPaid(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/BillIsPaid";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public deleteCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/CompanyMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/CompanyMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateCompany(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/CompanyMasterApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getAllCompanyMaster() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/CompanyMasterApi", { headers: headers, observe: 'response' });
	}

	public deleteFleet(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/FleetMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getFleet(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/FleetMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateFleet(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/FleetMasterApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getAllVesseles(fleet_id:any) {
		let token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let options:any = {headers: headers};
		if(fleet_id){
			const httpParams: HttpParamsOptions = { fromObject: {fleet_id:fleet_id} } as HttpParamsOptions;
			options.params = new HttpParams(httpParams);
		}
		return this.httpClient.get(this.baseURL + "api/VesselListWithoutFleet", options);
	}

	public deleteCurrency(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/CurrencyMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getCurrency(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/CurrencyMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateCurrency(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/CurrencyMasterApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public getBillToCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/BillToCompanyApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateBillToCompany(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/BillToCompanyApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}
	
	public getBillToCompanyDetails(so_id: any) {
		let data:any = {so_id:so_id}
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/GetBillToCompanyDetails", { params: new HttpParams(httpParams), headers: headers });
	}

	public restoreCompanyMaster(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_CompanyMaster/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreFleetMaster(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_FleetMaster/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public restoreCurrency(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_CurrencyMaster/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public SyncVesselInventryMedicines(so_id:any){
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL+"api/SyncVesselInventryMedicines",{so_id:so_id},{headers:headers});
	}

	public downloadCompletedOrdersExcel(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/CompletedOrdersExcelDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public uploadReferenseExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/UploadReferenseExcel", data, { headers: headers });
	}

	public deleteMedicineFromVesselInventry(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/DeleteMedicineFromVesselInventry";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public getInventoryRefFilePath(vesselId: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/GetUploadReferenseExcelName";
		return this.httpClient.post(url, {vessel_id:vesselId}, { headers: headers });
	}

	public isPaidPO(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/POIsPaid";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public mergeDuplicate3cidBatches(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/MergeDuplicate3cidBatches";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public UploadLogisticsDocuments(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadLogisticsDocuments";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public GetLastVendorAndPrice(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/GetLastVendorAndPrice", data, { headers: headers });
	}

	public excelDownloadForVendorsAndMedicines(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/excelDownloadForVendorsAndMedicines",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public purchaseOrderListAllDownload(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/PurchaseOrderListAllDownload", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getAllKits(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/KitMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createKit(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/KitMasterApi ";
		if(data.KitMaster.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.KitMaster.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public deleteKit(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/KitMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getKitDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/KitMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public restoreKit(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_kit/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public removeKitMappingItem(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/deleteKitItemsElement", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getKitItemsFromQuotation(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/GetKitItemsFromQuotation", data, { headers: headers });
	}

	public getLastKitId() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/KitMasterGetLast3cid", { headers: headers });
	}

	public checkKitItemsApi(quot_id: any) {
		let data:any = {quot_id:quot_id};
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/CheckKitItemsApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public activityMatrixExcelDownload() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.get(this.baseURL + "api/activityMatrixExcelDownload", { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public invoiceModuleAlertsExcelDownload() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.get(this.baseURL + "api/InvoiceModuleAlertsExcelDownload", { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public medicineStockMasterDetailsApiExcelDownload(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/MedicineStockMasterDetailsApiExcelDownload", { params: new HttpParams(httpParams), headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public autoGenerateInvoiceNo() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/AutoGenerateInvoiceNo", { headers: headers });
	}

	public getDashboardURL(){
		var user_type: any = localStorage.getItem('user_type');
		if(user_type=="Vessel"){
			return "/app/vessel-dashboard";
		}else{
			return "/app/admin-dashboard";
		}
	}

	public isPharmaUser(){
		var user_type: any = localStorage.getItem('user_type');
		if(user_type=="Vessel"){
			return false;
		}else{
			return true;
		}
	}

	public uploadCaptainSign(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/UploadCaptainSign", data, { headers: headers });
	}

	public uploadCaptainStamp(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/UploadCaptainStamp", data, { headers: headers });
	}

	public uploadReferenseImage(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/UploadReferencePhoto", data, { headers: headers });
	}

	public getInventoryRefImagePath(vesselId: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/GetUploadReferensePhotoName";
		return this.httpClient.post(url, {vessel_id:vesselId}, { headers: headers });
	}

	public getQuestionaireMaster() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/QuestionaireMasterApi";
		return this.httpClient.get(url, { headers: headers });
	}

	public autoGenerateQuesNo() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/AutoGenerateQuesNo";
		return this.httpClient.get(url, { headers: headers });
	}

	public deleteQuestionnaire(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/QuestionaireListApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public createUpdateQuestionnaire(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/QuestionaireListApi";
		if(data.QuestionaireList.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			delete data.QuestionaireList.Id;
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public consumptionLogTracking(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/ConsumptionLogTrackingApi";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public getQuestionnaireDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/QuestionaireListApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getAutoAlerts(vessel_id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {vessel_id:vessel_id} } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/AutoAlertsVesselInventry", { params: new HttpParams(httpParams), headers: headers });
	}

	public getConsumptionLog(vessel_id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {vessel_id:vessel_id} } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/ConsumptionLogVesselInventry", { params: new HttpParams(httpParams), headers: headers });
	}

	public getSOKitDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/GetItemsOfKit", data, { headers: headers });
	}

	public kitItemsExcelDownload(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/KitItemsExcelDownload",data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public pOItemsGenerateQRCode(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/POItemsGenerateQRCode", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public QRCodeScanningTest(po_item_id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {po_item_id:po_item_id} } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/QRCodeScanningTest", { params: new HttpParams(httpParams), headers: headers });
	}

	public fetchSalesOrderItems(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/fetchSalesOrderItems", { params: new HttpParams(httpParams), headers: headers });
	}

	public updateSalesOrderItems(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/updateSalesOrderItems";
		return this.httpClient.put(url, data, { headers: headers });
	}

	public sortSalesOrderItems(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/sortSalesOrderItems";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public deleteSalesOrderItemBatch(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/deleteSalesOrderItemBatch", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public fetchSalesOrderItem(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/fetchSingleSalesOrderItems", { params: new HttpParams(httpParams), headers: headers });
	}

	public getLostMedicineDetails(med_id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {med_id:med_id} } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/GetLostExpiredMedicine", { params: new HttpParams(httpParams), headers: headers });
	}

	public medicineLost(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/LostExpiredMedicineManagement";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public restoreLostExpiredMedicine(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/RestoreLostExpiredMedicine", {id:id}, { headers: headers });
	}

	public skipSalesOrderLinking(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/SkipSalesOrderLinking", {so_id:id}, { headers: headers });
	}

	public addMedicineToVesselInventry(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/AddMedicineToVesselInventry", data, { headers: headers });
	}

	public getVesselStatusList() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.get(this.baseURL + "api/VesselStatusApi", { headers: headers });
	}

	public vesselMasterExcelDownload(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/VesselMasterExcelDownload", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public deleteProvider(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/ProviderApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getProvider(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/ProviderApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateProvider(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/ProviderApi";
		if(data.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public restoreProvider(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_Provider/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public deleteFreight(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/FreightBillingMasterApi", { params: new HttpParams(httpParams), headers: headers, observe: 'response' });
	}

	public getFreight(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/FreightBillingMasterApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public createUpdateFreight(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/FreightBillingMasterApi";
		if(data.FreightBilling.Id){
			return this.httpClient.put(url, data, { headers: headers });
		}else{
			return this.httpClient.post(url, data, { headers: headers });
		}
	}

	public restoreFreight(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/restore_FreightBillingMaster/"+id+"/";
		return this.httpClient.get(url, { headers: headers });
	}

	public getAllCompletedOrders() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/GetAllCompletedOrders", {}, { headers: headers });
	}

	public addFreightPDF(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UploadFreightFile";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public updateShipmentPlaning(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let url = this.baseURL + "api/UpdateShipmentPlaning";
		return this.httpClient.put(url, data, { headers: headers });
	}

	public updateReviewerApproval(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/ShipmentReviewerApproval";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public updateLogisticApproval(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/ShipmentLogisticApproval";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public MCCIsReview(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let url = this.baseURL + "api/MCCIsReview";
		return this.httpClient.post(url, data, { headers: headers });
	}

	public getMailBoxCount() {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL + "api/MailBoxCount", { headers: headers });
	}

	public searchMail(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/MailMasterApi", options);
	}

	public unlinkMail(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "api/UnlinkEmailFromVessel", data, { headers: headers });
	}

	public deleteMail(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"api/delete_mail",data,{headers:headers});
	}

	public restoreMail(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"api/restore_mail",data,{headers:headers});
	}

	public getMailHistory(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "api/mail_history", data, { headers: headers });
	}

	public MarkAsRead(mId:any) {
		var data = {id:mId}
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "api/MarkAsRead", data, { headers: headers });
	}

	public updateMail(data:any) {
		/* if(data.appt_id == undefined){
			data.appt_id = null;
		} */
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.put(this.baseURL + "api/MailboxApi", data, { headers: headers });
	}

	public saveMail(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "api/ReplyToEmail", data, { headers: headers });
	}

	public MoveEmailToSpam(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "api/MoveEmailToSpam", data, { headers: headers });
	}

	public RemoveEmailFromSpam(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "api/RemoveEmailFromSpam", data, { headers: headers });
	}

	public LinkEmailToVessel(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "api/LinkEmailToVessel", data, { headers: headers });
	}

	public getquotationlistForMailBox(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/getquotationlistForMailBox", options);
	}

	public KitMasterExcelDownload(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/KitMasterExcelDownload", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public upload_PoItemMedImg(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		return this.httpClient.post(this.baseURL + "api/upload_PoItemMedImg", data, { headers: headers });
	}

	public delete_PoItemMedImg(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		return this.httpClient.post(this.baseURL + "api/delete_PoItemMedImg", data, { headers: headers });
	}
}

export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}
