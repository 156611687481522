import { FormControl } from "@angular/forms";

export const emailPattern = /^[a-zA-Z0-9]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,6}$/;
export const mobilePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export function noWhitespaceInRequiredValidator(control: FormControl) {
	let val:any = control.value;
	if(val == null){
		return { 'required': true };
	}
	let type = typeof val;
	if(type == "number" || type == "bigint" || type == "object"){
		return null;
	}
	const isWhitespace = (control.value || '').trim().length === 0;
	const isValid = !isWhitespace;
	return isValid ? null : { 'required': true };
}

export function getTitleForMed(allMedicines:any,Id:any){
	try {
		let len = allMedicines.length;
		for (let i = 0; i < len; i++) {
			if(allMedicines[i].Id == Id){
				return allMedicines[i].name;
			}
		}
		return "";
	} catch (error) {
		return "";
	}
}

export function getMedId(allMedicines:any,Id:any){
	try {
		let len = allMedicines.length;
		for (let i = 0; i < len; i++) {
			if(allMedicines[i].Id == Id){
				return allMedicines[i].medId;
			}
		}
		return "";
	} catch (error) {
		return "";
	}
}

export function getHSN(allMedicines:any,Id:any){
	try {
		let len = allMedicines.length;
		for (let i = 0; i < len; i++) {
			if(allMedicines[i].Id == Id){
				return allMedicines[i].HSN;
			}
		}
		return "";
	} catch (error) {
		return "";
	}
}

export function g_s_t_number_validation(control: FormControl) {
	let gstin:any = control.value;
	if(gstin == null || gstin === ""){
		return { 'gstn_required': true };
	}
	gstin = gstin.toUpperCase();
	var isAlphaNumeric = /^[a-z0-9]+$/i.test(gstin);
	if (!isAlphaNumeric) {
		return { 'gstn_alphanumeric': true };
	}
	//Chech if 15 characters
	if(gstin.length != 15){
		return { 'gstn_length': true };
	}
	//Check state code i.e. first 2 digits number from 1 to 37 or 98
	var stateCode = parseInt(gstin.substring(0, 2));
	var isStateCorrect = false;
	switch (true) {
		case (stateCode < 1):
		isStateCorrect = false;
		break;
		case (stateCode < 38):
		isStateCorrect = true;
		break;
		case (stateCode == 98):
		isStateCorrect = true;
		break;
		default:
		isStateCorrect = false;
		break;
	}
	if(!isStateCorrect){
		return { 'gstn_statecode': true };
	}
	//Pan Letters check 3rd to 7th and 12th are alphabets
	var panLetters = gstin.substring(2, 7) +  gstin.substring(11, 12);
	var letterCheck = /^[a-z]+$/i.test(panLetters);
	if(!letterCheck){
		return { 'gstn_pan': true };
	}
	//PAN numbers check 8th to 11th are numbers
	var panDigits = gstin.substring(7, 11);
	var digitCheck =/^\d+$/.test(panDigits);
	if(!digitCheck){
		return { 'gstn_pan': true };
	}
	//Second last character should be "Z"
	var defaultChar = gstin.substring(13, 14);
	if(defaultChar != "Z" && defaultChar != "z"){
		return { 'gstn_secondlast': true };
	}
	//last character Checksum
	var str = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	var lastChar = gstin.substring(14, 15);
	var first14Chars = gstin.substring(0, 14);
	var res = 0;
	for (var i = 0; i < first14Chars.length; i++) {
		var char = first14Chars.charAt(i);
		var placeValue = str.indexOf(char);
		var factor;
		if ((i+1)%2 == 0) {
			factor = 2;
		} else {
			factor = 1;
		}
		var multiplication = placeValue * factor;
		var quotient = Math.floor(multiplication/36);
		var remainder = multiplication % 36;
		res += quotient + remainder;
	}
	var res1 = res % 36;
	var res2 = (36-res1)%36;
	var checksumChar = str.charAt(res2);
	if (checksumChar != lastChar) {
		return { 'gstn_checksum': true };
	}
	return null;
}

export function pan_number_validation(control: FormControl){
	let pan_no:any = control.value;
	if(pan_no == null){
		return { 'pan_required': true };
	}
	if(pan_no.length != 10){
		return { 'invalid_pan': true };
	}
	pan_no = pan_no.toUpperCase();
	//Pan Letters check 1st to 5th and 10th are alphabets
	var panLetters = pan_no.substring(0, 5) +  pan_no.substring(9, 10);
	var letterCheck = /^[a-z]+$/i.test(panLetters);
	if(!letterCheck){
		return { 'invalid_pan': true };
	}
	//PAN numbers check 6th to 9th are numbers
	var panDigits = pan_no.substring(5, 9);
	var digitCheck =/^\d+$/.test(panDigits);
	if(!digitCheck){
		return { 'invalid_pan': true };
	}
	return null;
}

export function password_validation(control: FormControl) {
	let password:any = control.value;
	if(password==null || password==undefined){
		password = "";
	}
	let errObj:any = {};
	if(password.length < 8){
		errObj.minlengtherror = true;
	}
	const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
	if(!specialChars.test(password)){
		errObj.specialcharerror = true;
	}
	const upper = /[A-Z]/;
	const lower = /[a-z]/;
	if(!upper.test(password)){
		errObj.uppercaseerror = true;
	}
	if(!lower.test(password)){
		errObj.lowercaseerror = true;
	}
	return errObj?errObj:null;
}