import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
	transform(array: any, field: string): any[] {
		if(!field || field=="___"){
			return array;
		}
		array.sort((a: any, b: any) => {
			let fieldArr = field.split("___");
			let fld = fieldArr[0];
			let orderType = fieldArr[1];
			if(orderType == "asc"){
				if (a[fld] < b[fld]) {
					return -1;
				} else if (a[fld] > b[fld]) {
					return 1;
				} else {
					return 0;
				}
			}else if(orderType == "desc"){
				if (a[fld] > b[fld]) {
					return -1;
				} else if (a[fld] < b[fld]) {
					return 1;
				} else {
					return 0;
				}
			}else{
				return 0;
			}
			
		});
		return array;
	}
}