import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
	user: any = { email: '', password: '' };
	year: number = new Date().getFullYear();
	ScreenHeight: number = window.innerHeight;
	loadershow: boolean = false;
	loginErrorshow: boolean = false;
	checkCapsLock: boolean = false;
	loginFormErr: string = "";
	adminAccess:any;
	@ViewChild('bgVideo') bgVideo: ElementRef;
	data:any = null;
	step:number = 1;
	showQR = false;
	qrToShow:any;
	auth: any = { otp: null };
	qrStep = 1;
	
	constructor(private apiService: ApiService, private router: Router, private renderer: Renderer2, private toastr: ToastrService) {}

	ngAfterViewInit(): void {}
	
	ngOnInit(): void {
		if(localStorage.getItem('token') != null && localStorage.getItem('token') != undefined){
			this.gotoDashboard();
		}
		if(localStorage.getItem('sessionexpire') == "true"){
			this.loginErrorshow = true;
			this.loginFormErr = "Your session has been expired.";
			setTimeout(() => {
				this.loginErrorshow = false;
				localStorage.removeItem('sessionexpire')
			}, 5000);
		}
	}
	
	submitForm(user: any) {
		this.loadershow = true;
		this.apiService.loginAPI(user).subscribe((data:any) => {
			this.loadershow = false;
			this.data = data;
			/* this.data.enable2fa=true;this.data.first_login=true; */
			if(this.data.enable2fa && this.data.first_login){
				this.step = 2;
				this.showQR = true;
				this.generateQR();
			}else if(this.data.enable2fa && !this.data.first_login){
				this.step = 2;
				this.showQR = false;
				this.qrStep = 2;
			}else{
				this.proceedLogin();
			}
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Username Or Password is incorrect.";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		}
		);
	}

	gotoDashboard(){
		this.router.navigate([this.apiService.getDashboardURL()]);
	}

	next(){
		this.qrStep = 2;
	}

	generateQR(){
		this.loadershow = true;
		let data:any = {
			Id:this.data.phuser,
			user:this.data.emailId
		};
		this.apiService.generate_qr(data).subscribe((data:any) => {
			this.loadershow = false;
			this.createImageFromBlob(data);
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Something went wrong";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	createImageFromBlob(image: Blob) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.qrToShow = reader.result;
		}, false);
		if (image) {
			reader.readAsDataURL(image);
		}
	}

	verifyOTP(){
		if(!this.auth.otp){
			this.toastr.warning("Please enter OTP", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.loadershow = true;
		let data:any = {
			Id:this.data.phuser,
			otp:this.auth.otp
		};
		this.apiService.verifyGoogleAuthOTP(data).subscribe((data:any) => {
			this.loadershow = false;
			this.proceedLogin();
		},(error:any)=>{
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Invalid OTP";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	proceedLogin(){
		localStorage.setItem('token', "Bearer "+this.data["token"]);
		localStorage.setItem('emailId', this.data["emailId"]);
		localStorage.setItem('FirstName', this.data["FirstName"]);
		localStorage.setItem('LastName', this.data["LastName"]);
		localStorage.setItem('phuser', this.data["phuser"]);
		localStorage.setItem('user_type', this.data["user_type"]);
		localStorage.setItem('vessel_id', this.data["vessel_id"]?this.data["vessel_id"]:"");
		/* localStorage.setItem('UserModuleAccess', JSON.stringify(this.data["UserModuleAccess"])); */
		this.toastr.success("Logged in successfully", 'Success',{
			tapToDismiss: true,
			closeButton: true,
			timeOut:3000
		});
		this.gotoDashboard();
	}
}
