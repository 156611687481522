import { Subscription, Observable } from 'rxjs';
// import 'rxjs/add/operator/debounceTime';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../api.service';
// import { MatSelect } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
// import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { EventEmitter, Output} from '@angular/core';
// import * as _ from 'lodash';
declare var $: any;
// import { debounceTime, map, distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from '../../environments/environment';
declare const Pusher: any;
import { LazyLoadScriptService } from './../lazy-load-script/lazy-load-script.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { noWhitespaceInRequiredValidator } from '../shared/constants';


@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	year: number = new Date().getFullYear();
	resetPasswordForm!: FormGroup;
	loadershow = false;
	user:any = null;
	token:any = null;
	linkExpired = false;
	constructor(private toastr: ToastrService,private apiService: ApiService,private formBuilder: FormBuilder,private router: Router,private activeRoute: ActivatedRoute,private zone: NgZone,private lazyLoadService: LazyLoadScriptService) {}
	
	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.user = routeParams["user"];
			this.token = routeParams["token"];
			this.resetPasswordForm = this.formBuilder.group({
				password: ['', [Validators.required,noWhitespaceInRequiredValidator,this.passwordMinLengthValidator(8),this.passwordCapitalValidator(),this.passwordNumericValidator()]],
				confirmPassword: ['', [Validators.required,noWhitespaceInRequiredValidator]],
			}, {
				validators: this.passwordMatchValidator.bind(this)
			});
			this.checkLinkExpiry();
		});
	}

	checkLinkExpiry(){
		let data = {
			user:this.user,
			token:this.token
		}
		this.apiService.checkLinkExpiry(data).subscribe(data => {
			this.linkExpired = false;
		},(error)=>{
			this.linkExpired = true;
		});
	}

	passwordMatchValidator(formGroup: FormGroup) {
		const password = formGroup.get('password');
		const confirmPassword = formGroup.get('confirmPassword');
		return password!.value === confirmPassword!.value ? null : { passwordNotMatch: true };
	}
	
	passwordMinLengthValidator(min: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if(password==null){
				return null;
			}
			if(password.length >= min){
				return null;
			}else{
				return {minlengtherror:true};
			}
		};
	}
	
	passwordCapitalValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if(password==null){
				return null;
			}
			if(password.replace(/[^A-Z]/g, "").length >= 1){
				return null;
			}else{
				return {capitalerror:true};
			}
		};
	}
	
	passwordNumericValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			let password = control.value;
			if(password==null){
				return null;
			}
			if(password.replace(/[^0-9]/g, "").length >= 1){
				return null;
			}else{
				return {numericerror:true};
			}
		};
	}
	
	get f() { return this.resetPasswordForm.controls; }

	resetPassword(){
		if (this.resetPasswordForm.invalid) {
			return;
		}
		let data = {
			user:this.user,
			token:this.token,
			password:this.f.password.value
		}
		this.loadershow = true;
		this.apiService.setNewPassowrd(data).subscribe(data => {
			this.loadershow = false;
			this.clearResetPassword();
			this.toastr.info("Password changed successfully.", 'Success!!!',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.router.navigate(["/login"]);
		},(error)=>{
			this.loadershow = false;
			if(error.status==401){return}
			this.toastr.error("Error while changing password.", 'Error!',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	clearResetPassword(){
		this.f.password.setValue(null);
		this.f.confirmPassword.setValue(null);
		this.f.password.updateValueAndValidity();
		this.f.confirmPassword.updateValueAndValidity();
		this.resetPasswordForm.markAsUntouched();
		this.resetPasswordForm.reset();
	}
	
}
